

/*https://iros.github.io/patternfills/sample_css.html*/
#home___tool-bar-name {
  display: flex;
  justify-content: space-between;
  border-bottom: solid;
}

#home___namebar {
  font-size: 3.25vh;
  padding-left: 1vh;
  padding-right: 1vh;
  white-space: nowrap;
  font-family: "VT323", monospace;
  padding-bottom: 1px;
  padding-top: 1px;
}

.home___namebar-fill {
  margin: 5px;
  width: 100%;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="10"><line x1="0" y1="0" x2="100%" y2="0" stroke="black" stroke-width="2" /><line x1="0" y1="10" x2="100%" y2="10" stroke="black" stroke-width="2" /></svg>');
  background-repeat: repeat;
}

#home___tool-bar {
  width: 100%;
  display: flex;
  flex-direction: column;

  border-bottom: double;
  padding-bottom: 1px;
  border-width: thick;
}

#home___tool-bar___entries {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 3vh;
  margin-left: 1px;
}

#home___tool-bar-tiles {
  height: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1px;
}
.home___tool-bar__entry {
  font-family: "VT323", monospace;
  font-size: 3vh;
  color: black;
}

.home___tool-bar__entry:hover {
  background-color: black;
  color: white;
}

.home___tool-bar__entry-text {
  margin: 0;
  padding-left: 1vh;
  padding-right: 1vh;
  line-height: 3vh;
}

.home___tool-bar__exit {
  line-height: 2.5vh;
}

.home__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box; /* Include padding in width calculation */
  width: 90%; /* Increase width slightly */
  max-width: 600px;
  padding: 0 20px;
  margin: 0 auto; /* Center using auto margins */
  height: 100%;
  overflow-y: scroll;
  /* Remove any conflicting margin-left/right */
}



.output {
  font-family: "IBM Plex Mono", monospace;
  color: var(--licorice);
  margin-top: 0;
  margin-bottom: 0;
}
.output > h1 {
  font-size: 2.5vh;
}

/* Cursor Styling */

.cursor::after {
  content: "";
  display: inline-block;
  margin-left: 3px;
  background-color: rgb(8, 8, 8);
  animation-name: blink;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  line-height: 1vh;
  vertical-align: middle;
}

h1.cursor::after {
  height: 2.6vh;
  width: 1vh;
  line-height: 2.6vh;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.home__typewriter {
  padding-top: 1vh;
  margin-top: 0;
  width: 100%; /* Ensure full width within parent */
}

#home___text {
  width: 100%; /* Ensure full width within parent */
}


.mainbox-text {
  font-family: "IBM Plex Mono", monospace;
  font-size: 2vh;
  color: var(--licorice);
  width: 100%;
  margin: 1vh 0; /* Add vertical margin between paragraphs */
}

.home___tool-bar-button {
  text-decoration: none;
  text-decoration-color: black;
  color: black;
}

.home___link-text {
  font-family: "IBM Plex Mono", monospace;
  font-size: 2vh;
  color: var(--licorice);
  margin-top: 1px;
}

#home___image {
  display: block;
  margin: 0;  /* Remove margin */
  height: 15vh;
  width: auto;
  max-width: 100%;
  max-height: 15vh;
  object-fit: contain;
}

/*https://stackoverflow.com/questions/6370690/media-queries-how-to-target-desktop-tablet-and-mobile*/
@media only screen and (max-device-width: 480px) {
  #mainbox {
    background-color: var(--white);
    height: 80%;
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    padding-top: 1px;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 20px 20px 10px #0a3b76;
  }

  .output > h1 {
    font-size: 2.5vh;
  }

  .mainbox-text {
    font-size: 2vh;
  }

  #home___namebar {
    font-size: 3.25vh;
  }
  .home___tool-bar__entry {
    font-size: 2vh;
  }

  .aboutme {
    display: block;
  }
}

@media only screen and (min-width: 1900px) {
  #mainbox {
    height: 50%;
    width: 50%;
    min-width: 500px;
    max-width: 800px;
  }
  .output > h1 {
    font-size: 1.75vh;
  }
  .mainbox-text {
    font-size: 1.5vh;
  }
  #home___namebar {
    /*width:100%;*/
    font-size: 2.25vh;
  }
  .home___tool-bar__entry {
    font-size: 2vh;
  }
  .home___tool-bar__entry-text {
    line-height: 2vh;
  }
  #home___tool-bar___entries {
    height: 2vh;
  }
  #home___tool-bar-tiles {
    height: 2vh;
  }
}

/*

https://www.coffee-break-designs.com/labs/svg_stripe_generator/
https://iros.github.io/patternfills/sample_css.html

font-family: 'Press Start 2P', cursive;
font-family: 'IBM Plex Mono', monospace;
font-family: 'VT323', monospace;
*/

.projects-section {
  margin-top: 20px; /* Adjust as needed */
  font-family: "VT323", monospace; /* Use the same font */
}

.projects-title {
  font-size: 3vh; /* Adjust size to match your design */
  margin-bottom: 10px; /* Space below title */
}

.project-card {
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Space between projects */
  border: 1px solid black; /* Optional: Add a border for separation */
  padding: 10px; /* Padding inside each card */
}

.project-image {
  width: 100px; /* Set a fixed size or adjust as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 15px; /* Space between image and text */
}

.project-info {
  flex-grow: 1; /* Allow info to take remaining space */
}

.project-name {
  font-size: 2.5vh; /* Adjust size to match your design */
  margin: 0; /* Reset default margin */
}

.project-description, .project-tech-stack {
  font-size: 2vh; /* Adjust size to match your design */
  margin: 0; /* Reset default margin */
}

.project-links {
  margin-top: 5px; /* Space above links */
}

.project-links a {
  color: blue; /* Link color */
  text-decoration: underline; /* Underline for links */
}

.project-links a:hover {
  color: darkblue; /* Change color on hover */
}


.projects-section {
  margin-top: 20px; /* Space between sections */
  /* You can adjust this margin or other styling as needed */
}

/* Optional: if you want to add a specific height for the project section */
.project-card {
  margin-bottom: 15px; /* Space between projects */
}

#mainbox {
  display: flex;
  align-items: center; /* Ensure child content is centered */
}

.home__typewriter, 
#home___text {
  width: 100%;
  box-sizing: border-box;
}